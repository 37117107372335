<template>
  <div class="spell">
    <img class="logo" src="../../assets/images/dragon.gif" alt="">
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import header from '@/component/header/header';
import { kbgetLive } from '@/services/api.js'
import { urlConversion } from '@/utils/public.js'

export default {
// import引入的组件需要注入到对象中才能使用
  components: {
    // header
  },
  data() {
    // 这里存放数据
    return {}
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.goUrl()
  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  },
  // 方法集合
  methods: {
    goUrl() {
      const obj = urlConversion('obj').value
      const mid = localStorage.getItem('uid')
      if (mid) {
        const data = {
          uid: mid,
          dbredirect: obj.dbredirect
        }
        kbgetLive(data).then((res) => {
          if (Number(res.code) === 200) {
            window.location.href = res.data.url
          }
        })
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    }

  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.logo {
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
